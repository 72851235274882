import {createBrowserRouter} from "react-router-dom";
import React from "react";
import App from "./App";
// import Login from "./login/Login";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>
    },
    // {
    //     path: "/login",
    //     element: <Login/>
    // }
])

export default router